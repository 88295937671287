<template>


<div style="text-align:center;background:white">
    <table>
        <tr>
            <td style="text-align:center">
                <img src="https://i.imgur.com/jz0fDYw.png">
            </td>
        </tr>
        <tr>
            <td style="text-align:center;padding-top:20px">
                ขอแสดงความยินดีกับคุณ XXX XXXX<br><span style="color:#0880bf">ได้รับส่วนลด 5% </span>จากการลงทะเบียนร่วมกิจกรรม
            </td>
        </tr>
        <tr>
            <td style="text-align:center">
            <hr>
            </td>
        </tr>
        <tr>
            <td style="text-align:center">
            <h3 style="padding:0px">คูปองส่วนลด 5%</h3>
            <h1 style="color:#0880bf;font-size:60px;padding:0px">EPSXXXX</h1>
            <h4 style="padding:0px">สำหรับสั่งซื้อน้ำดื่มตรา เอท พลัส</h4>
            </td>
        </tr>
        <tr>
            <td style="text-align:center">
            <hr>
            </td>
        </tr>
        <tr>
            <td style="text-align:center">
            <strong>*คูปองมีอายุการใช้งานถึงวันที่ 31 ธันวาคม 2563</strong>
            </td>
        </tr>
        <tr>
            <td style="text-align:center;padding-top:40px">
                <img src="https://i.imgur.com/p6dC9EK.png">
                <h3>สแกนเพื่อสอบถามหรือสั่งซื้อ</h3>
            </td>
        </tr>
        <tr>
            <td style="padding-top:40px">
                <strong>เงื่อนไขการรับลิงค์คูปองส่วนลด 5%</strong><br>
                1. ลิงค์ส่วนลดนี้ขอสงวนสิทธิ์ให้กับลูกค้าที่ทำการลงทะเบียนผ่าน www.8pluswater.com<br>
                2. คูปองส่วนลด 5%  เมื่อกดลงทะเบียนร่วมกิจกรรม เป็นส่วนลด On top จากโปรโมชั่นปัจจุบัน ใช้ซื้อ<br>
                น้ำดื่มตรา เอท พลัส ผ่านทาง Facebook/Line my shop ต่อ 1 คำสั่งซื้อเท่านั้น (ไม่สามารถใช้ได้กับ
                ช่องทางการซื้อ ผ่าน Modern trade และช่องทางออนไลน์ Shopee, Lazada และ JD central)<br>
                3. สำหรับลูกค้าที่ลงทะเบียนผ่าน www.8pluswater.com  เปิดรับลงทะเบียนตั้งแต่ 1 ตุลาคม 2563
                จนถึงวันที่ 31 ธันวาคม 2563 และสามารถใช้ได้ถึง 31 ธันวาคม 2563 นี้เท่านั้น<br>
                4. คูปองส่วนลด 5% เมื่อซื้อ น้ำดื่มตราเอทพลัส ผ่านทางไดเรค Facebook  , Line my shop 
                (ไม่สามารถใช้ได้กับช่องทางการซื้อ ผ่าน Modern trade และช่องทางออนไลน์ Shopee, Lazada
                และ JD central) สามารถใช้ได้ 1 สิทธิ์ ต่อ 1 ท่าน ต่อ 1 คำสั่งซื้อเท่านั้น  (ไม่จำกัดจำนวนการสั่งซื้อ)<br>
                5. 1 สิทธิ์ สามารถใช้ซื้อสินค้าที่ร่วมรายการ 1 สิทธ์ /รายการ/คำสั่งซื้อ (ไม่จำกัดจำนวนการสั่งซื้อ)<br>
                6. ไม่สามารถเปลี่ยนหรือแลกเป็นเงินสดได้<br>
                7.บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขโดยมิต้องแจ้งให้ทราบล่วงหน้า<br>
                8. ลงทะเบียนกับทาง www.8pluswater.com  เพื่อยืนยันรับสิทธิ์คูปอง ส่วนลด 5% เมื่อซื้อสินค้า 
                น้ำดื่มตรา เอท พลัส ผ่านช่องทาง Facebook / Line my shop  (ไม่สามารถใช้ได้กับช่องทางการซื้อ
                ผ่าน Modern trade และช่องทางออนไลน์ Shopee, Lazada และ JD central)<br>
                9. ทางแบรนด์ขอสงวนสิทธิ์การใช้ คูปองส่วนลด 5%  เมื่อซื้อสินค้า น้ำดื่มตราเอทพลัส ผ่านช่องทาง
                Facebook /Line my shop  (ไม่สามารถใช้ได้กับช่องทางการซื้อ ผ่าน Modern trade และช่องทาง
                ออนไลน์ Shopee, Lazada และ JD central) สำหรับคนที่ลงทะเบียนกับทางแบรนด์เท่านั้น<br>

                **หมายเหตุ ถ้าพบปัญหาหรือข้อสงสัยสามารถติดต่อหรือแจ้งปัญหาได้ที่ chat inbox ทางเพจ <br>
                FB : Eight Plus Water
            </td>
        </tr>
    </table>
</div>

</template>